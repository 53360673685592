<template>
  <OnboardingStep
    :title="$t('onboarding.automationsIntroView.title')"
    :next-text="$t('onboarding.automationsIntroView.nextText')"
    v-bind="$props"
  >
    <I18nArray tag="p" path="onboarding.automationsIntroView.paragraphs">
      <template #help-link>
        <RaiLink :href="$t('onboarding.automationsIntroView.helpLink.href')">
          {{ $t("onboarding.automationsIntroView.helpLink.text") }}
        </RaiLink>
      </template>
    </I18nArray>
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import I18nArray from "@/components/I18nArray";
import { RaiLink } from "@/core-ui";

export default {
  name: "AutomationsIntroView",
  components: { I18nArray, RaiLink },
  extends: BaseStepView,
};
</script>
